import CryptoJS from 'crypto-js';

export const encryptText = (data: string): string => {
    try {
        const key: string | undefined = process.env.REACT_APP_CRYPTO_KEY; // Replace this with your secret key

        if (!key) {
            throw new Error('Crypto key is undefined');
        }

        const encrypted = CryptoJS.AES.encrypt(data, key).toString();
        return encrypted;
    } catch (error) {
        throw error;
    }
};

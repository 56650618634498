import React, { useEffect, ReactNode } from "react";

interface DesktopModeWrapperProps {
  children: ReactNode;
}

const DesktopModeWrapper: React.FC<DesktopModeWrapperProps> = ({ children }) => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobileOrTablet =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    const originalViewport = document.querySelector('meta[name="viewport"]')?.getAttribute("content");

    if (isMobileOrTablet) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        // Force desktop mode by setting the width to 1024px
        viewportMeta.setAttribute("content", "width=1024");
      }
    }

    // Cleanup function to restore original viewport meta tag when component unmounts
    return () => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta && originalViewport) {
        viewportMeta.setAttribute("content", originalViewport);
      }
    };
  }, []);

  return <>{children}</>;
};

export default DesktopModeWrapper;

import React, { useState } from "react";
import './Loader.css';

const Loading = () => {
  return (
    <div className="loading-overlay" id="loading">
      <div className="loading-wrap">
        <div className="loading">
          <div></div>
          <div></div>
        </div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

const IsLoadingHOC = (WrappedComponent: any) => {
  function HOC(props: any) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = (isComponentLoading: boolean) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <Loading />}
        <WrappedComponent
          {...props}
          isLoading={isLoading}
          setLoading={setLoadingState}
        />
      </>
    );
  }
  return HOC;
};

export default IsLoadingHOC;

import { createSlice } from "@reduxjs/toolkit";
import { AuthData } from "../../model/auth";
import { RootState } from "../../redux/store";

const initialState: AuthData = {
  theme: sessionStorage?.getItem("user_theme") || "dark",
  userData: JSON.parse(sessionStorage?.getItem("user") || "null") as string,
  token: sessionStorage.getItem("jwt_token") || null,
  isLoggedIn: sessionStorage.getItem("user") ? true : false,
  error: null,
  balance: null,
  exposure: null,
  userSelfDownline:null,
};

const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    balanceSuccess: (state, action: any) => {
      state.balance = action.payload.Balance;
      state.exposure = action.payload.Exposure;
    },
    loginSuccess: (state, action: any) => {
      sessionStorage.setItem("user", JSON?.stringify(action?.payload));
      sessionStorage.setItem("jwt_token", action?.payload?.Token);
      state.userData = action.payload;
      state.token = action.payload.Token;
      state.isLoggedIn = true;
      state.error = null;
    },
    selfDownline: (state, action: any) => {
      state.userSelfDownline = action.payload;
    },
    loginFailure: (state, action: any) => {
      state.userData = null;
      state.isLoggedIn = false;
      //state.error = action.payload;
      state.balance = null;
    },
    logout: (state) => {
      sessionStorage.clear();
      state.userData = null;
      state.isLoggedIn = false;
      state.token = null;
      state.error = null;
      state.balance = null;
    },
    changePasswordFailure: (state, action: any) => {
      state.error = action.payload;
    },
    changeTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
      sessionStorage.setItem("user_theme", state.theme);
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  changePasswordFailure,
  balanceSuccess,
  selfDownline,
  changeTheme,
} = authSlice.actions;
export const userData = (state: RootState) => state.auth.userData;
export const isLoggedIn = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;

import moment from "moment-timezone";

const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const isMobileDevice1 = window.matchMedia("(max-width: 767px)").matches;

export const isMobile = isMobileDevice || isMobileDevice1 ? true : false;

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone:"UTC"
  };

  const formattedDate: string = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
};
const isiOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isIOSMobileDevice1 = window.matchMedia("(max-width: 767px)").matches;

export const isIOSMobile = isiOSDevice && isIOSMobileDevice1;



export const newDateTimeFormat = (dateString: string): string => {
  return moment(dateString).format('YYYY-MM-DD HH:mm')
}
export const newDateTimeFormatwithss = (dateString: string): string => {
  return moment(dateString).format('YYYY-MM-DD HH:mm:ss')
}

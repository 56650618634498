import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "./redux/hooks";
import { isLoggedIn } from "./redux/index";
import "./variables.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { MessageProvider } from "./context/MesssageContext";
import ChangePassword from "./changepassword";

const Layout = React.lazy(() => import("./Layout"));
const Login = React.lazy(() => import("./login"));
let is_mobile = window.innerWidth<620?true:false;
const App = () => {
  const user = useAppSelector((state: any) => state.auth);
  const islogin = useAppSelector(isLoggedIn);
  useEffect(() => {
    
    if (!islogin) {
      if(window.innerWidth < 620){
        document.body.setAttribute("class",`login-bg mobile-login-wrap`);
      }
      else{
        document.body.setAttribute("class",`login-bg desktop-login-wrap`);
      }
    }
    else if(user?.userData.ChangePassword===1 && !is_mobile){
      
      document.body.setAttribute("class", "change-password-bg");
    }
    else {
      document.body.setAttribute("class", "");
    }
  }, [islogin]);
  
  return (
    <React.Fragment>
      <MessageProvider>
        {!islogin ? <Login /> : user?.userData.ChangePassword===1?<ChangePassword/>:<Layout/>}
      </MessageProvider>
    <ToastContainer autoClose={5000} position="top-right"/>
    </React.Fragment>
  )
};

export default App;

import React, { useState, useMemo, createContext, ReactNode } from 'react';

interface TimeZones {
  [key: string]: string;
}

// Define the types for the context values
interface MessageContextProps {
  displayWarning: boolean;
  warningContent: string;
  setDisplayWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningContent: React.Dispatch<React.SetStateAction<string>>;
  displayError: boolean;
  errorContent: string;
  setDisplayError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorContent: React.Dispatch<React.SetStateAction<string>>;
  displaySuccess: boolean;
  successContent: string;
  setDisplaySuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessContent: React.Dispatch<React.SetStateAction<string>>;
  displayLoader: boolean;
  setDisplayLoader: React.Dispatch<React.SetStateAction<boolean>>;
  accountBalance: string;
  setAccountBalance: React.Dispatch<React.SetStateAction<string>>;
  timeZones: TimeZones;
  setTimeZones: React.Dispatch<React.SetStateAction<TimeZones>>;
}

// Create the context with the defined types
export const MessageContext = createContext<MessageContextProps | undefined>(undefined);

// Define the types for the provider props
interface MessageProviderProps {
  children: ReactNode;
}

export const MessageProvider: React.FC<MessageProviderProps> = ({ children }) => {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorContent, setErrorContent] = useState<string>('');
  const [displaySuccess, setDisplaySuccess] = useState<boolean>(false);
  const [successContent, setSuccessContent] = useState<string>('');
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [warningContent, setWarningContent] = useState<string>('');
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [accountBalance, setAccountBalance] = useState<string>('');
  const [timeZones, setTimeZones] = useState<TimeZones>({
    "Pacific/Midway": "Pacific/Midway(GMT-11:00)",
    "Pacific/Honolulu": "Pacific/Honolulu(GMT-10:00)",
    "America/Juneau": "America/Juneau(GMT-9:00)",
    "America/Los_Angeles": "America/Los_Angeles(GMT-8:00)",
    "America/Phoenix": "America/Phoenix(GMT-7:00)",
    "America/Chicago": "America/Chicago(GMT-6:00)",
    "America/New_York": "America/New_York(GMT-5:00)",
    "America/Santiago": "America/Santiago(GMT-4:00)",
    "America/Sao_Paulo": "America/Sao_Paulo(GMT-3:00)",
    "Atlantic/South_Georgia": "Atlantic/South_Georgia(GMT-2:00)",
    "Atlantic/Azores": "Atlantic/Azores(GMT-1:00)",
    "Europe/London": "Europe/London(GMT+0:00)",
    "Europe/Paris": "Europe/Paris(GMT+1:00)",
    "Africa/Cairo": "Africa/Cairo(GMT+2:00)",
    "Asia/Qatar": "Asia/Qatar(GMT+3:00)",
    "Asia/Dubai": "Asia/Dubai(GMT+4:00)",
    "Asia/Karachi": "Asia/Karachi(GMT+5:00)",
    "IST": "IST(Bangalore / Bombay / New Delhi) (GMT+5:30)",
    "Asia/Kathmandu": "Asia/Kathmandu(GMT+5:45)",
    "Asia/Dhaka": "Asia/Dhaka(GMT+6:00)",
    "Asia/Bangkok": "Asia/Bangkok(GMT+7:00)",
    "Asia/Hong_Kong": "Asia/Hong_Kong(GMT+8:00)",
    "Asia/Tokyo": "Asia/Tokyo(GMT+9:00)",
    "Australia/Adelaide": "Australia/Adelaide(GMT+9:30)",
    "Australia/Melbourne": "Australia/Melbourne(GMT+10:00)",
    "Asia/Magadan": "Asia/Magadan(GMT+11:00)",
    "Pacific/Fiji": "Pacific/Fiji(GMT+12:00)"
});
  const value = useMemo(
    () => ({
      displayWarning,
      warningContent,
      setDisplayWarning,
      setWarningContent,
      displayError,
      errorContent,
      setDisplayError,
      setErrorContent,
      displaySuccess,
      successContent,
      setDisplaySuccess,
      setSuccessContent,
      displayLoader,
      setDisplayLoader,
      accountBalance,
      setAccountBalance,
      timeZones,
      setTimeZones
    }),
    [displayWarning, warningContent, displayError, errorContent, displaySuccess, successContent, displayLoader, accountBalance]
  );

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
};
